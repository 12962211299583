export const fileTypesImages = [
  {
    mime: 'image/jpeg',
    extension: '.jpeg',
  },
  {
    mime: 'image/jpg',
    extension: '.jpg',
  },
  {
    mime: 'image/png',
    extension: '.png',
  },
  {
    mime: 'image/gif',
    extension: '.gif',
  },
];

export const fileTypesDocuments = [
  {
    mime: 'application/pdf',
    extension: '.pdf',
  },
];

export const fileTypesVideos = [
  {
    mime: 'video/mp4',
    extension: '.mp4',
  },
];

export const sources = {
  FILES: { id: 'files', label: 'My files' },
  UNSPLASH: { id: 'unsplash', label: 'Unsplash' },
};
